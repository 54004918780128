import React from 'react';
import { LogoProps } from './types';

function User({ width, height, color }: LogoProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.53337 5.30252C4.86795 5.30252 4.22979 5.07587 3.75927 4.67243C3.28875 4.26899 3.02441 3.72181 3.02441 3.15126C3.02441 2.58071 3.28875 2.03353 3.75927 1.63009C4.22979 1.22665 4.86795 1 5.53337 1C6.19879 1 6.83695 1.22665 7.30747 1.63009C7.77799 2.03353 8.04233 2.58071 8.04233 3.15126C8.04233 3.72181 7.77799 4.26899 7.30747 4.67243C6.83695 5.07587 6.19879 5.30252 5.53337 5.30252Z"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
      />
      <path
        d="M0.515625 10.6807V10.1429C0.515625 9.28708 0.912129 8.46631 1.61791 7.86115C2.32369 7.25599 3.28094 6.91602 4.27906 6.91602H6.78802C7.78614 6.91602 8.74339 7.25599 9.44917 7.86115C10.1549 8.46631 10.5515 9.28708 10.5515 10.1429V10.6807"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default User;
